<template>
  <div class="connect-wallet d-flex flex-column align-center justify-center">
    <!-- Heading -->
    <h2 v-text="$t('wallet.connect.title')" />

    <!-- Subheading -->
    <p
      v-text="$t('wallet.connect.subtitle')"
      class="mb-10 mt-5 text--secondary"
    />

    <!-- Api response -->
    <api-response :response="formResponse" class="mb-5" />

    <!-- Button -->
    <v-btn color="primary" @click="connectMetamask">
      {{ $t("wallet.connect.button") }}
    </v-btn>
  </div>
</template>

<script>
import { ethers } from "ethers";

export default {
  data: () => ({
    formResponse: null,
    hasMetamask: true,
  }),

  methods: {
    async connectMetamask() {
      this.formResponse = null;

      if (!window.ethereum) {
        this.hasMetamask = false;
        window.open("https://metamask.io/download/", "_blank");
        return this.$toast.error("Please install MetaMask extension.");
      } else {
        this.hasMetamask = true;
      }

      try {
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          "any"
        );

        await provider.send("eth_requestAccounts", []);

        const signer = provider.getSigner();
        const walletAddress = await signer.getAddress();
        const message = await this.$store.dispatch("auth/getSignMessage", {
          public_address: walletAddress,
        });
        const signature = await signer.signMessage(message);
        await this.$store.dispatch("auth/walletLogin", {
          signature,
          sign_message: message,
          public_address: walletAddress,
        });

        this.$store.commit("auth/SET_USER", {
          ...this.$user,
          public_address: walletAddress,
        });
      } catch (e) {
        this.formResponse = e;
      }
    },
  },
};
</script>

<style></style>
